<template>
  <div class="edu-frame" style="background: #ededed">
    <edu-nav-bar title="朋友圈" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>
    <div class="class-comment">
      <van-field
          v-model="pyq.comment"
          label-width="0px"
          placeholder="请填写您的心得"
          label-align="top"
          rows="3"
          autosize
          type="textarea"
          maxlength="1000"
          show-word-limit
      />

      <van-uploader v-model="fileList" multiple :max-count="9" style="margin-left: 10px;margin-top: 10px;" :after-read="changeFile" @delete="changeFile"/>
    </div>


    <div style="width: 90%;margin: 20px auto;">
      <van-button :loading="submitting" square block type="primary" @click="submitPyq">确定</van-button>

    </div>
  </div>
</template>
<script>
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import Tools from "@/api/Tools";
import {Button, Field,  Uploader} from "vant";
import VolunteerApi from "@/api/Volunteer";
export default {
  components: {
    EduNavBar,
    VanField: Field,
    VanButton: Button,
    VanUploader: Uploader
  },
  data() {
    return {
      cust: Tools.getCurCust(),
      show: false,
      pyq: {},
      fileList: [],
      files: [],
      submitting: false
    }
  },
  methods: {
    changeFile(e) {
      console.log('e.url == ', e)
      if (e.url != null) {
        var fileID = null;
        var fileIndex = -1
        for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].base64img == e.url) {
            fileID = this.files[i].fileID
            fileIndex = i
            break
          }
        }
        console.log('fileID == ' + fileID)
        if (fileID != null) {
          this.files.splice(fileIndex, 1)
          // this.deleteFileIDs.push(fileID)
        }
      } else {
        var self = this
        self.files = []
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].file != null) {
              // this.files.push({ base64img: this.fileList[i].content, fileName: self.fileList[i].file.name })
            Tools.compressFile(this.fileList[i].file).then(response=> {
              console.log('file response', response)
              Tools.uploadFileByBase64(response, (base64Response)=>{
                // console.log(base64Response)
                self.files.push({ base64img: base64Response, fileName: response.name })
              })
            })
            // this.files.push({ base64img: this.fileList[i].content, fileName: this.fileList[i].file.name })
          }
        }

      }
      // this.changeFileList()
    },
    submitPyq() {
      if (this.submitting) {
        return
      }
      for (var i = 0; i < 9; i++) {
        this.pyq['pic'+i+1] = ''
        if (i < this.files.length) {
          this.pyq['pic'+(i+1)] = this.files[i].base64img
        }
      }
      this.pyq.custId = this.cust.custId
      this.submitting = true
      VolunteerApi.submitPyq(this.pyq).then(response => {
        this.submitting = false

        if (response.code == 100) {
          this.$dialog.alert({message: "发布成功，待管理员审批成功后张辉展示在朋友圈中。"})
          this.$router.back()
        } else {
          this.$dialog.alert({message: response.msg})
        }
      }).catch(() => { this.submitting = false })
    }
  }
}
</script>
<style scoped>
.class-comment {
  width: 95%;
  margin: 15px auto 0;
  background: white;
}
</style>
